<template>
  <div class="pagination">
    <el-pagination
      background
      small
      style="text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="parseInt(this.currentPage) || 1"
      :page-sizes="pageSizes || [10, 20, 30]"
      :page-size="parseInt(this.pageSize) || 10"
      :layout="!pagination?'prev, pager, next':'total, sizes, prev, pager, next,jumper'"
      :total="parseInt(this.total)"
      :pager-count="5"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "FortressPagination",
  props: {
    total: [Number, String],
    currentPage: [Number, String],
    pageSizes: Array,
    pageSize: [Number, String],
  },
  data () {
    return {
      pagination: true
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit("refreshData", {
        current: parseInt(this.currentPage) || 1,
        size: val,
      });
    },
    handleCurrentChange (val) {
      this.$emit("refreshData", {
        current: val,
        size: parseInt(this.pageSize) || 10,
      });
    },
  },
  created () {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (w <= 768) {
      this.pagination = false
    } else {
      this.pagination = true
    }

  },
  mounted () {
    window.addEventListener(
      "resize",
      () => {
        var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
        if (w <= 768) {
          this.pagination = false
        } else {
          this.pagination = true
        }
      },
      false,
    )
  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: flex-end;
}
.pagination
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #0cafcc;
  color: #fff;
}
.pagination /deep/ .el-pager li.active {
  color: #fff !important;
}
</style>
