import request from "@/common/request"

//表单数据保存
export const saveData = (data) => {
    return request('post', '/formManage/saveData', data)
}

//表单数据分页展示
export const pageData = (queryForm) => {
    return request("get", "/formManage/pageData", queryForm);
}

//表单数据详情
export const dataInfo = (tbId, id) => {
    return request('get', '/formManage/info', {
        tbId: tbId,
        id: id
    })
}

//表单数据批量删除
export const dataDelBatch = (tbId, ids) => {
    return request('post', '/formManage/deleteBatch', {
        tbId: tbId,
        ids: ids
    })
}

//表单数据动态表头导出
export const dataDynamicExport = (data) => {
    return request("eptpost", '/formManage/exportDynamic', data)
}