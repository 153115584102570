<template>
  <el-dialog
    :title="
      rowId == 'undefine' || rowId == null || rowId == '' ? '新增' : '编辑'
    "
    :visible.sync="visible"
    :close-on-click-modal="false"
    @close="closeHandler"
  >
    <iframe
      v-show="isIframeLoaded"
      ref="previewPage"
      class="result-wrapper"
      frameborder="0"
      src="/preview.html"
      @load="iframeLoad"
    />
    <div v-show="!isIframeLoaded" v-loading="true" class="result-wrapper"></div>
  </el-dialog>
</template>

<script>
import {
  exportDefault
} from '@/components/fortress-draggable/utils/index'
import {
  makeUpHtml
} from '@/components/fortress-draggable/generator/html'
import { makeUpJs } from '@/components/fortress-draggable/generator/datajs'
import { makeUpCss } from '@/components/fortress-draggable/generator/css'
import {
  formconfigInfo
} from "@/api/form/formconfig.js"
import { parse } from '@babel/parser'

const editorObj = {
  html: null,
  js: null,
  css: null
}
export default {
  data() {
    return {
      visible: false,
      isIframeLoaded: false,
      isInitcode: false, // 保证open后两个异步只执行一次runcode
      isRefreshCode: false, // 每次打开都需要重新刷新代码
      htmlCode: '',
      jsCode: '',
      cssCode: '',
      formData: {},
      generateConf: { type: 'file' },
      formConfigData: {
        id: "",
        designContent: "",
        tbContent: "",
        viewContent: ""
      },
      form: {},
      rowId: ''
    }
  },
  created() {
    let _this = this;
    window.dataeditfinish = function () {
      _this.$message({
        type: "success",
        message: "操作成功!",
      })
      _this.$emit("refreshData")
      _this.closeHandler();
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init(tbId, id) {
      this.formConfigData.id = tbId + "",
        this.rowId = id,
        formconfigInfo(tbId).then((resp) => {
          if (resp.code == 0) {
            if (resp.data.viewContent) {
              this.visible = true;
              this.formData = JSON.parse(resp.data.viewContent);
              this.onOpen();
            } else {
              this.drawingList = [];
            }
          }
        });
    },
    onOpen() {
      const { type } = this.generateConf
      this.htmlCode = makeUpHtml(this.formData, type)
      this.jsCode = makeUpJs(this.formData, type, this.formConfigData.id, this.rowId)
      this.cssCode = makeUpCss(this.formData)
      this.setEditorValue('editorHtml', 'html', this.htmlCode)
      this.setEditorValue('editorJs', 'js', this.jsCode)
      this.setEditorValue('editorCss', 'css', this.cssCode)
      this.runCode()

    },
    setEditorValue(id, type, codeStr) {
      editorObj[type] = codeStr
    },
    runCode() {
      const jsCodeStr = editorObj.js
      try {
        const ast = parse(jsCodeStr, { sourceType: 'module' })
        const astBody = ast.program.body
        if (astBody.length > 1) {
          this.$confirm(
            'js格式不能识别，仅支持修改export default的对象内容',
            '提示',
            {
              type: 'warning'
            }
          )
          return
        }
        if (astBody[0].type === 'ExportDefaultDeclaration') {
          const postData = {
            type: 'refreshFrame',
            data: {
              generateConf: this.generateConf,
              html: this.htmlCode,
              js: jsCodeStr.replace(exportDefault, ''),
              css: this.cssCode,
              scripts: [],
              links: []
            }
          }
          let _this = this;
          let timer = setInterval(function () {
            if (_this.$refs.previewPage) {
              _this.isIframeLoaded = true;
              _this.$refs.previewPage.contentWindow.postMessage(
                postData,
                location.origin
              )
              clearInterval(timer);
            }
          }, 50)

        } else {
          this.$message.error('请使用export default')
        }
      } catch (err) {
        // this.$message.error(`js错误：${err}`)
        console.error(err)
      }
    },
    iframeLoad() {
      // if (!this.isInitcode) {
      //   this.isIframeLoaded = true
      //   this.isRefreshCode && (this.isInitcode = true) && this.runCode()
      // }
      this.runCode()
    },
    //关闭
    closeHandler() {
      console.log("closeHandler");
      this.rowId = ''
      this.visible = false;
      this.isIframeLoaded = false;
      this.isInitcode = false; // 保证open后两个异步只执行一次runcode
      this.isRefreshCode = false; // 每次打开都需要重新刷新代码
    }
  }
}
</script>

<style lang='scss' scoped>
@import "@/components/fortress-draggable/styles/index";
@import "@/components/fortress-draggable/styles/home";

.result-wrapper {
  height: calc(100vh - 33px);
  width: 100%;
  overflow: auto;
  padding: 12px;
  box-sizing: border-box;
}
</style>
