<template>
  <div class="padding20">
    <el-row style="margin-bottom: 7px">
      <el-form
        :inline="true"
        :model="queryForm"
        @submit.native.prevent
        @keyup.enter.native="queryList"
        v-if="conditionFlag"
      >
        <fragment v-for="c in queryConditions" :key="c.model">
          <el-form-item v-if="c.checked">
            <fragment
              v-if="
                ['el-select', 'el-radio-group', 'el-checkbox-group'].includes(
                  c.tag
                )
              "
            >
              <el-select
                v-model="queryForm[c.model]"
                :placeholder="`请选择${c.title}`"
                clearable
                @change="forceUpdate"
              >
                <el-option
                  v-for="o in selectList[c.model]"
                  :key="o.value"
                  :label="o.label"
                  :value="o.value"
                ></el-option>
              </el-select>
            </fragment>
            <fragment v-else-if="['el-cascader'].includes(c.tag)">
              <el-cascader
                v-model="queryForm[c.model]"
                :options="selectList[c.model]"
                @change="forceUpdate"
              ></el-cascader>
            </fragment>
            <fragment v-else>
              <el-input
                v-model="queryForm[c.model]"
                autocomplete="off"
                :placeholder="`请输入${c.title}`"
                clearable
                @input="forceUpdate"
              ></el-input>
            </fragment>
          </el-form-item>
        </fragment>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-search"
            @click="queryList({ current: 1 })"
            >查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            size="medium"
            icon="el-icon-refresh-left"
            @click="resetQueryForm"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
      <el-button
        type="primary"
        size="medium"
        icon="el-icon-plus"
        v-if="$tools.hasPermission('form:formConfig:save')"
        @click="handleEdit()"
        >添加</el-button
      >
      <el-button
        v-if="$tools.hasPermission('form:formConfig:deleteBatch')"
        size="medium"
        type="danger"
        icon="el-icon-delete"
        @click="handleDeleteBatch()"
        >删除</el-button
      >
      <div style="float: right; padding-right: 17px">
        <i
          class="el-icon-download"
          style="margin-right: 10px; cursor: pointer"
          @click="handleExport"
        ></i>
        <i
          class="el-icon-refresh"
          style="margin-right: 10px; cursor: pointer"
          @click="refresh"
        ></i>

        <el-popover
          placement="bottom"
          width="180"
          trigger="click"
          title="列展示"
          popper-class="columnShows"
        >
          <el-checkbox-group v-model="tableShowList">
            <el-checkbox
              v-for="item in tableList"
              :label="item.propName"
              :key="item.propName"
              :value="item.propName"
              >{{ item.propDesc }}</el-checkbox
            >
          </el-checkbox-group>
          <i
            slot="reference"
            class="el-icon-s-operation"
            style="cursor: pointer"
          ></i>
        </el-popover>
      </div>
    </el-row>
    <el-table
      ref="multipleTable"
      :data="tableData"
      style="width: 100%; margin-bottom: 20px"
      row-key="id"
      border
    >
      <el-table-column
        type="selection"
        width="55"
        show-overflow-tooltip
      ></el-table-column>
      <fragment v-for="col in configData.fields" :key="col.__vModel__">
        <fragment
          v-if="
            col.__config__.tag == 'el-select' ||
            col.__config__.tag == 'el-radio-group'
          "
        >
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
            :formatter="formatHandler"
          >
          </el-table-column>
        </fragment>
        <fragment v-else-if="col.__config__.tag == 'el-switch'">
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
            :formatter="switchHandler"
          >
          </el-table-column>
        </fragment>
        <fragment v-else-if="col.__config__.tag == 'el-checkbox-group'">
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
            :formatter="checkboxHandler"
          >
          </el-table-column>
        </fragment>
        <fragment v-else-if="col.__config__.tag == 'fortress-editor'">
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <p v-html="scope.row[col.__vModel__]"></p>
            </template>
          </el-table-column>
        </fragment>
        <fragment v-else-if="col.__config__.tag == 'el-cascader'">
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
            :formatter="cascaderHandler"
          >
          </el-table-column>
        </fragment>
        <fragment v-else>
          <el-table-column
            v-if="$tools.isShow(col.__vModel__, tableShowList)"
            :prop="col.__vModel__"
            :label="col.__config__.label || col.__vModel__"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        </fragment>
      </fragment>
      <el-table-column label="操作" fixed="right" min-width="180">
        <template slot-scope="scope">
          <el-button
            v-if="$tools.hasPermission('form:formConfig:edit')"
            size="mini"
            plain
            @click="handleEdit(scope.row.id)"
            >编辑</el-button
          >
          <el-button
            v-if="$tools.hasPermission('form:formConfig:delete')"
            size="mini"
            plain
            type="danger"
            @click="handleDelete(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination
      :total="page.total"
      :current-page="page.current"
      :page-size="page.size"
      @refreshData="queryList"
    ></pagination>
    <!-- 新增、编辑 -->
    <form-edit
      v-if="formEditVisible"
      ref="formEdit"
      @refreshData="queryList"
    ></form-edit>
  </div>
</template>
<script>
import { viewTableList } from "@/api/public.api.js";
import FormEdit from "./formdata-edit";
import FortressPagination from "@/components/fortress-pagination.vue";
import { enumList } from "@/api/public.api.js";
import {
  formconfigInfo
} from "@/api/form/formconfig.js"
import { pageData, dataDelBatch, dataDynamicExport } from "@/api/form/formmanage.js";
export default {
  data() {
    return {
      conditionFlag: false,
      tableData: [],
      formEditVisible: false,
      formInfoVisible: false,
      queryForm: {
        tbId: "",
      },
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      collapse: {
        icon: "el-icon-arrow-down el-icon--right",
        label: "展开",
        flag: false,
      },
      tableList: [],
      tableShowList: [],
      configData: {
        id: "",
        fields: [],
      },
      formData: {},
      //查询条件配置
      queryConditions: [],
      //选择项集合
      selectList: []
    };
  },
  components: {
    "form-edit": FormEdit,
    pagination: FortressPagination,
  },
  created() {
    this.configData.id = this.$route.params.id + '';
    if (this.configData.id) {
      this.queryForm.tbId = this.configData.id
      this.initInfo(this.configData.id)
    }

  },
  watch: {
    queryForm: {
      deep: true,
      handler: function (val) {
        console.log(val);
      }
    }
  },
  methods: {
    initInfo(id) {
      formconfigInfo(id).then((resp) => {
        if (resp.code == 0) {
          if (resp.data.tbContent) {
            this.configData.fields = JSON.parse(resp.data.tbContent);
            this.initSelectList();
            this.initQueryCondition(resp.data.queryCondition);
            this.queryEnumList();
            this.initTableList();
            this.queryList();
          } else {
            this.drawingList = [];
          }
        }
      });
    },
    //查询条件
    initQueryCondition(conditions) {
      if (conditions) {
        this.queryConditions = JSON.parse(conditions);
        if (this.queryConditions.length > 0) {
          this.queryConditions.forEach(item => {
            if (item.checked) {
              this.queryForm[item.model] = "";
              this.conditionFlag = true
            }
          })
        }
      }
    },
    //选择项集合
    initSelectList() {
      this.configData.fields.forEach(s => {
        if (['el-radio-group', 'el-select', 'el-checkbox-group'].includes(s.__config__.tag)) {
          if (s && s.__slot__ && s.__slot__.options && s.__slot__.options.length > 0) {
            this.selectList[s.__vModel__] = s.__slot__.options
          } else {
            this.selectList[s.__vModel__] = []
          }
        } else if (['el-cascader'].includes(s.__config__.tag)) {
          if (s && s.options && s.options.length > 0) {
            this.selectList[s.__vModel__] = s.options
          } else {
            this.selectList[s.__vModel__] = []
          }
        }
      })
    },
    queryEnumList() {
      this.YesNoList = [];
      enumList("YesNo", "").then((resp) => {
        if (resp.code == 0) {
          this.YesNoList = resp.data;
        }
      });
    },
    refresh() {
      this.tableData = [];
      this.queryList({ current: 1 });
    },
    //查询表格数据
    queryList(page) {
      if (page) {
        this.page.current = page.current ? page.current : this.page.current;
        this.page.size = page.size ? page.size : this.page.size;
      }
      this.queryForm.current = this.page.current;
      this.queryForm.size = this.page.size;
      let queryData = {};
      queryData.tbId = this.queryForm.tbId
      queryData.current = this.page.current;
      queryData.size = this.page.size;
      queryData.conditionJson = JSON.stringify(this.filterQueryData());
      pageData(queryData).then((resp) => {
        if (resp.code == 0) {
          this.tableData = resp.data.records;
          this.page.total = resp.data.total;
          //查询页大于总页数，重新查询
          let cu =
            this.page.total % this.page.size == 0
              ? parseInt(this.page.total / this.page.size)
              : parseInt(this.page.total / this.page.size + 1);
          if (cu > 0 && this.page.current > cu) {
            this.queryList({ current: cu });
          }
        } else {
          this.$msg({
            message: resp.msg,
            type: "error",
          });
        }
      });
    },
    //初始化查询接口的查询条件
    filterQueryData() {
      let d = {};
      for (var key in this.queryForm) {
        if ("tbId" != key && "current" != key && "size" != key && this.queryForm[key] != "") {
          if (Array.isArray(this.queryForm[key])) {
            //级联查询数组转字符串
            d[key] = this.queryForm[key].toString();
            //TODO 多个查询条件需要用 @;@ 分隔符隔开(日期等)
          } else {
            d[key] = this.queryForm[key]
          }

        }
      }
      return d;
    },
    //打开编辑窗口
    handleEdit(id) {
      this.formEditVisible = true;
      this.$nextTick(() => {
        this.$refs.formEdit.init(this.configData.id, id);
      });
    },
    //删除
    handleDelete(row) {
      this.$confirm("确认删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = [];
          ids.push(row.id)
          dataDelBatch(this.configData.id, ids).then((resp) => {
            if (resp.code == 0) {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              //判断是否最后一页的最后一条记录
              let totalPages =
                (this.page.total - 1) % this.page.size == 0
                  ? (this.page.total - 1) / this.page.size
                  : (this.page.total - 1) / this.page.size + 1;
              if (this.page.current > totalPages && this.page.current != 1) {
                this.page.current = this.page.current - 1;
              }
              this.queryList();
            } else {
              this.$msg({
                message: resp.msg,
                type: "error",
              });
            }
          });
        })
        .catch(() => { });
    },
    //批量删除
    handleDeleteBatch() {
      let checkedRow = this.$refs.multipleTable.selection;
      if (checkedRow.length > 0) {
        let ids = [];
        checkedRow.forEach((item) => {
          ids.push(item.id);
        });
        this.$confirm("确认删除该记录, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            dataDelBatch(this.configData.id, ids).then((resp) => {
              if (resp.code == 0) {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                //判断是否最后一页的最后一条记录
                let totalPages =
                  (this.page.total - checkedRow.length) % this.page.size == 0
                    ? (this.page.total - checkedRow.length) / this.page.size
                    : (this.page.total - checkedRow.length) / this.page.size +
                    1;
                if (this.page.current > totalPages && this.page.current != 1) {
                  this.page.current = this.page.current - 1;
                }
                this.queryList();
              } else {
                this.$msg({
                  message: resp.msg,
                  type: "error",
                });
              }
            });
          })
          .catch(() => { });
      } else {
        this.$msg({
          message: "请选择数据进行删除",
          type: "error",
        });
      }
    },
    //重置查询框
    resetQueryForm() {
      for (var key in this.queryForm) {
        if ("tbId" != key && "current" != key && "size" != key) {
          this.queryForm[key] = ""
        }
      }
      this.forceUpdate();
    },
    //切换查询条件收缩框
    handleCollapseState() {
      this.collapse.flag = !this.collapse.flag;
      if (this.collapse.flag) {
        this.collapse.icon = "el-icon-arrow-up el-icon--right";
        this.collapse.label = "收起";
      } else {
        this.collapse.icon = "el-icon-arrow-down el-icon--right";
        this.collapse.label = "展开";
      }
    },
    //视图表格列表初始化
    initTableList() {
      viewTableList(this.configData.id).then((resp) => {
        if (resp.code == 0) {
          this.tableList = resp.data;
          this.tableList.forEach((item) => {
            this.tableShowList.push(item.propName);
          });
        }
      });
    },
    //可见字段excel导出
    handleExport() {
      if (!this.tableShowList || this.tableShowList.length < 1) {
        this.$msg({
          message: "请选择要导出的列",
          type: "error",
        });
        return;
      }
      let colNum = 1;
      let headerList = [];
      this.tableShowList.forEach((item) => {
        let nameData = this.tableList.find((item2) => {
          return item2.propName == item;
        });
        let name = "";
        if (nameData && nameData.propDesc) {
          name = nameData.propDesc;
        } else {
          name = "未定义字段" + colNum++;
        }
        headerList.push({ name: name, code: item });
      });
      let queryData = {};
      queryData.tbId = this.queryForm.tbId
      queryData.current = this.page.current;
      queryData.size = this.page.size;
      queryData.conditionJson = JSON.stringify(this.filterQueryData());
      dataDynamicExport({ headerList: headerList, dto: queryData });
    },
    //表单设计
    handleDesign(id) {
      this.$router.push({ path: "/form/formconfig-design", query: { id: id } });
    },
    //表格设计
    gridHandleDesign(id) {
      console.log("gridHandleDesign", id);
    },
    //表单预览
    handlePreview(row) {
      this.$router.push({ path: "/formView", query: { id: row.id } });
    },
    //搜索条件绑定
    forceUpdate() {
      this.$forceUpdate()
    },
    //数据格式化
    formatHandler(row, column, cellValue) {
      let name = cellValue;
      let pro = column.property;
      if (this.selectList[pro] && this.selectList[pro].length > 0) {
        let d = this.selectList[pro].find(item => {
          return item.value == cellValue;
        })
        if (d) {
          name = d.label;
        }
      }
      return name;
    },
    //开关数据格式化显示
    switchHandler(row, column, cellValue) {
      let name = "";
      if (cellValue == true) {
        name = "开"
      } else if (cellValue == false) {
        name = "关"
      }
      return name;
    },
    //多选框数据格式化显示
    checkboxHandler(row, column, cellValue) {
      let name = cellValue;
      let pro = column.property;
      if (this.selectList[pro] && this.selectList[pro].length > 0) {
        if (cellValue != null) {
          name = "";
          let c = cellValue.split(",");
          if (c && c.length > 0) {
            c.forEach(cc => {
              let d = this.selectList[pro].find(item => {
                return item.value == cc;
              })
              if (d) {
                name = name + d.label + ",";
              }
            })
          }
          if (name.lastIndexOf(",") == name.length - 1) {
            name = name.substring(0, name.length - 1);
          }
        }
      }
      return name;
    },
    //关联框数据格式化显示
    cascaderHandler(row, column, cellValue) {
      let name = cellValue;
      let pro = column.property;
      let d = this.selectList[pro];
      if (d && d.length > 0 && cellValue != null) {
        name = "";
        let c = cellValue.split(",");
        if (c && c.length > 0) {
          let l = c.length;
          let index = 0;
          do {
            let v = d.find(item => {
              return item.value == c[index];
            })
            console.log("v", v);
            if (v) {
              name = name + v.label + "/";
            }

            index = index + 1;
            console.log(index, c.length, d, l);
            if (index < c.length) {
              d = v.children;
              l = l - 1;
            } else {
              l = -1;
            }
          } while (l > 0 && d && d.length > 0);
        }
        if (name.lastIndexOf("/") == name.length - 1) {
          name = name.substring(0, name.length - 1);
        }
      }
      return name;
    }
  },
};
</script>
<style scoped>
</style>
